<script setup lang="ts">
const formState = reactive({
  clientName: '',
  email: '',
  question: '',
  submitted: false,
  error: '',
})

const clientNameError = ref(false)
const emailError = ref(false)
const questionError = ref(false)

// Compute whether the fields are filled
const clientNameFilled = computed(() => !!formState.clientName.trim())
const emailFilled = computed(() => !!formState.email.trim())
const questionFilled = computed(() => !!formState.question.trim())

const clearError = (fieldName: string) => {
  switch (fieldName) {
    case 'clientName':
      clientNameError.value = false
      break
    case 'email':
      emailError.value = false
      break
    case 'question':
      questionError.value = false
      break
    default:
      break
  }
}

const handleSubmit = async (event: Event) => {
  event.preventDefault()

  clientNameError.value = !clientNameFilled.value
  emailError.value = !emailFilled.value
  questionError.value = !questionFilled.value

  if (!clientNameError.value && !emailError.value && !questionError.value) {
    const { clientName, email, question } = formState
    try {
      const response = await fetch('/api/webshop/client-question', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ clientName, email, question }),
      })

      if (!response.ok) {
        throw new Error('Napaka pri pošiljanju, poizkusite kasneje')
      }

      formState.submitted = true
    } catch (error) {
      if (error instanceof Error) {
        formState.error = error.message
      } else {
        formState.error = 'Napaka pri pošiljanju, poizkusite kasneje'
      }
    }
  }
}
</script>

<template>
  <div class="container w-full mx-auto px-4">
    <div class="bg-white p-5 md:p-12 rounded-btn relative max-w-7xl mx-auto">
      <div
        class="text-h4w font-bold font-serif italic leading-18 text-green text-center mb-12"
      >
        {{ $t('components.questionsEmail.title') }}
      </div>

      <div class="grid md:grid-cols-2 gap-4 md:gap-12">
        <div class="">
          <div class="text-h6w font-bold mb-5 leading-9">
            {{ $t('components.questionsEmail.subtitle') }}
          </div>
          <div class="text-lg mb-6">
            {{ $t('components.questionsEmail.description') }}
          </div>
          <nuxt-img
            provider="s3"
            src="/img_contact.svg"
            title="Contact Image"
            class="absolute bottom-0 left-0"
            sizes="(max-width: 500px) 100vw, 50vw"
            loading="lazy"
          />
        </div>
        <div v-if="!formState.submitted" class="f-default">
          <form
            method="post"
            action="/redirect-url"
            @submit.prevent="handleSubmit"
          >
            <div class="z-2">
              <div class="mb-5">
                <div class="text-base text-black mb-1">
                  {{ $t('components.questionsEmail.nameAndSurname') }}
                </div>
                <input
                  v-model="formState.clientName"
                  type="text"
                  class="input input-website bg-white relative z-2"
                  name="name_and_surname"
                  :placeholder="
                    $t('components.questionsEmail.nameAndSurnamePlaceholder')
                  "
                  :class="{
                    'input-error': clientNameError,
                    'input-filled': clientNameFilled,
                  }"
                  @input="clearError('clientName')"
                />
              </div>
              <div class="mb-5">
                <div class="text-base text-black mb-1">
                  {{ $t('components.questionsEmail.email') }}
                </div>
                <input
                  v-model="formState.email"
                  type="email"
                  class="input input-website bg-white relative z-2"
                  name="email"
                  :placeholder="
                    $t('components.questionsEmail.emailPlaceholder')
                  "
                  :class="{
                    'input-error': emailError,
                    'input-filled': emailFilled,
                  }"
                  @input="clearError('email')"
                />
              </div>
              <div class="mb-5">
                <div class="text-base text-black mb-1">
                  {{ $t('components.questionsEmail.yourQuestion') }}
                </div>
                <textarea
                  v-model="formState.question"
                  type="text"
                  class="input input-website bg-white relative z-2"
                  name="question"
                  :placeholder="
                    $t('components.questionsEmail.yourQuestionPlaceholder')
                  "
                  rows="6"
                  :class="{
                    'input-error': questionError,
                    'input-filled': questionFilled,
                  }"
                  @input="clearError('question')"
                ></textarea>
              </div>
              <div v-if="formState.error" class="text-center mb-6">
                <div class="text-red">
                  {{ formState.error }}
                </div>
              </div>

              <button
                buttonName="menu-button"
                class="btn-lg solid-green w-full bg-white relative z-2"
              >
                {{ $t('buttons.send') }}
                <font-awesome-icon
                  :icon="['fas', 'arrow-right']"
                  class="icon-right-anim"
                />
              </button>
            </div>
          </form>
        </div>

        <div v-else class="f-submited">
          <div class="text-center mt-8 mb-32">
            <font-awesome-icon
              :icon="['fas', 'check']"
              class="text-h3w text-green"
            />
            <div
              class="text-h5w font-bold font-serif italic leading-18 text-green"
            >
              Sporočilo poslano!
            </div>
            <div class="text-xl">Odgovorili vam bomo v najkrajšem času</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
